import { Button, ContentContainer, Header } from '../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../components/App'
import { axiosInstance } from '../methods/axiosConfig'
import { devLog } from '../methods/devLog'
import { forceRedirect } from '../methods/forceRedirect'

export const MocksPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState

  const [mocks, setMocks] = useState([
    { label: 'Select scenario', scenario: '' },
  ])
  const [selectedMock, setSelectedMock] = useState(null)

  const getMocks = async () => {
    try {
      const apiUrl = process.env.WEB_API_URL

      const res = await axiosInstance.get(
        `${apiUrl}/${process.env.ONBOARDING_WEB_API_PATH}/mock/scenarios`,
        {
          withCredentials: true,
        }
      )
      res.data &&
        setMocks([
          ...mocks,
          ...res.data.map((mock) => {
            return {
              value: mock.scenario,
              label: mock.scenario,
            }
          }),
        ])
    } catch (e) {
      devLog(e)
    }
  }

  useEffect(() => {
    getMocks()
  }, [])

  const handleProceed = async () => {
    const apiUrl = process.env.WEB_API_URL
    forceRedirect(
      `${apiUrl}/${process.env.ONBOARDING_WEB_API_PATH}/mock/redirect?scenario=${selectedMock}`
    )
  }

  return (
    <ContentContainer
      {...theme.container}
      width={theme.contentWrap.width || '560px'}
      paddingMobile="20px"
      marginMobile="0"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
          Select mock scenario
        </Header>
        <select
          style={{ width: '300px', padding: '15px', margin: '20px 0' }}
          onChange={(e) => {
            setSelectedMock(e.target.value)
          }}
        >
          {mocks.length > 0 ? (
            mocks.map((mock, v) => (
              <option key={'mock-option' + v} value={mock.scenario}>
                {mock.label}
              </option>
            ))
          ) : (
            <option disabled value="">
              No mock scenarios
            </option>
          )}
        </select>

        <Button onClick={handleProceed} disabled={!selectedMock}>
          Proceed
        </Button>
      </div>
    </ContentContainer>
  )
})
