import React from 'react'
import { Container } from './Symbol.styles'

import { Bank } from './symbols/bank'
import { Camera } from './symbols/camera'
import { DrivingLicense } from './symbols/drivingLicense'
import { Euro } from './symbols/euro'
import { Globe } from './symbols/globe'
import { IdentityCard } from './symbols/identityCard'
import { Letter } from './symbols/letter'
import { Lightbulb } from './symbols/lightbulb'
import { Passport } from './symbols/passport'
import { ResidencePermit } from './symbols/residencePermit'
import { SocietyStatement } from './symbols/societyStatement'
import { UtilityBill } from './symbols/utilityBill'

export const SymbolTypes = [
  'society-statement',
  'utility-bill',
  'driving-license',
  'letter',
  'passport',
  'identity-card',
  'globe',
  'camera',
  'euro',
  'lightbulb',
  'bank',
  'residence-permit',
]

export interface SymbolProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any
  size?: string
  type:
    | 'society-statement'
    | 'utility-bill'
    | 'driving-license'
    | 'letter'
    | 'passport'
    | 'identity-card'
    | 'globe'
    | 'camera'
    | 'euro'
    | 'lightbulb'
    | 'bank'
    | 'residence-permit'
  primary?: string
  secondary?: string
  tenary?: string
}

export const Symbol = ({
  size,
  type,
  primary,
  secondary,
  tenary,
}: SymbolProps) => {
  function renderIcon() {
    switch (type) {
      case 'society-statement':
        return <SocietyStatement />
      case 'utility-bill':
        return <UtilityBill />
      case 'driving-license':
        return <DrivingLicense />
      case 'letter':
        return <Letter />
      case 'passport':
        return <Passport />
      case 'identity-card':
        return <IdentityCard />
      case 'globe':
        return <Globe />
      case 'camera':
        return <Camera />
      case 'euro':
        return <Euro />
      case 'lightbulb':
        return <Lightbulb />
      case 'bank':
        return <Bank />
      case 'residence-permit':
        return <ResidencePermit />
    }
  }

  return (
    <Container
      size={size || '58px'}
      primary={primary}
      secondary={secondary}
      tenary={tenary}
    >
      {renderIcon()}
    </Container>
  )
}
