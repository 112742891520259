import {
  Button,
  ContentContainer,
  Header,
  Icon,
  Symbol,
} from '../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect } from 'react'
import { StoreContext } from '../components/App'
import {
  ButtonImgWrap,
  ButtonText,
  OnboardingIcon,
  OnboardingItem,
  OnboardingList,
  OnboardingSingleLine,
} from '../styles/generic.styles'
import {
  ContentContainerBottom,
  ContentContainerBottomLeft,
} from '../styles/layout.styles'

export const VideoOnboardingPage = observer(() => {
  const store = useContext(StoreContext)
  const { currentPageIndex, context } = store.ScenarioState
  const { theme } = store.InterfaceState
  const { docType } = store.AppState

  const { videoOnboardingPage: trans } = store.TranslationsState.translations

  const handleProceed = async () => {
    store.ScenarioState.setCurrentPage(currentPageIndex + 1)
  }

  // useEffect(() => {
  //   if (!context?.showVideoIdHintsScreen) {
  //     store.ScenarioState.setCurrentPage(currentPageIndex + 1)
  //   }
  // }, [])

  return (
    <ContentContainer
      {...theme.container}
      width={theme.contentWrap.width || '560px'}
      paddingMobile="20px"
      marginMobile="0"
    >
      <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
        {trans.header}
      </Header>

      <OnboardingList>
        <OnboardingItem {...theme.onboardingItem}>
          <OnboardingIcon {...theme.symbol}>
            <Symbol type="utility-bill" {...theme.symbol} />
          </OnboardingIcon>
          <OnboardingSingleLine>{trans.wellLitPlace}</OnboardingSingleLine>
        </OnboardingItem>
        <OnboardingItem {...theme.onboardingItem}>
          <OnboardingIcon {...theme.symbol}>
            <Symbol type="identity-card" {...theme.symbol} />
          </OnboardingIcon>
          <OnboardingSingleLine>{trans.originalDocument}</OnboardingSingleLine>
        </OnboardingItem>
        <OnboardingItem {...theme.onboardingItem}>
          <OnboardingIcon {...theme.symbol}>
            <Symbol type="globe" {...theme.symbol} />
          </OnboardingIcon>
          <OnboardingSingleLine>{trans.wifi} </OnboardingSingleLine>
        </OnboardingItem>
      </OnboardingList>

      <ContentContainerBottom>
        <ContentContainerBottomLeft />
        <Button
          onClick={() => handleProceed()}
          disabled={
            context?.shouldSelectDocument ? !(docType && docType.length) : false
          }
          {...theme.button}
          width="164px"
          paddingMobile="14px 40px"
        >
          <>
            <ButtonImgWrap>
              {!theme.button.hideIcon && <Icon size="18px" type="checkmark" />}
            </ButtonImgWrap>
            <ButtonText>{trans.start}</ButtonText>
          </>
        </Button>
      </ContentContainerBottom>
    </ContentContainer>
  )
})
