import { ContentContainer, LoadingSpinner, Header } from '../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { MarginedWithHeight } from '../styles/layout.styles'
import { StoreContext } from '../components/App'
import { devLog } from '../methods/devLog'
import { axiosInstance } from '../methods/axiosConfig'
import { forceRedirect } from '../methods/forceRedirect'
import { useInterval } from 'usehooks-ts'
import { usePageVisibility } from '../hooks/usePageVisibility'

interface PollingResponse {
  status: string
  shouldWait: boolean
  proceedUrl?: string
}

export const VerifyingPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState
  const { context } = store.ScenarioState
  const { pixelSpeak: trans } = store.TranslationsState.translations
  const apiUrl = process.env.WEB_API_URL

  const [shouldWait, setShouldWait] = useState(true)
  const [shouldPoll, setShouldPoll] = useState(true)

  const isVisible = usePageVisibility()

  const checkStatus = async (): Promise<PollingResponse> => {
    try {
      const res = await axiosInstance.get<PollingResponse>(
        `${apiUrl}/${process.env.ONBOARDING_WEB_API_PATH}/waiting`,
        { withCredentials: true }
      )

      if (!res.data.shouldWait) {
        setShouldWait(false)

        forceRedirect(res.data.proceedUrl)
      }

      return res.data
    } catch (e) {
      devLog(e)
    }
  }

  useEffect(() => {
    setShouldPoll(isVisible)
  }, [isVisible])

  useEffect(() => {
    shouldPoll && checkStatus()
  }, [shouldPoll])

  useInterval(checkStatus, shouldWait && shouldPoll ? 3000 : null)

  return (
    <ContentContainer
      {...theme.container}
      width={theme.contentWrap.width || '560px'}
      paddingMobile="20px"
      marginMobile="10px"
    >
      <>
        <MarginedWithHeight margin="0 0 15px" height="0">
          <Header
            {...theme.header}
            fontFamily={theme.globals.fontFamilyHeadline}
          >
            {trans.verifying}
          </Header>
        </MarginedWithHeight>
        <LoadingSpinner
          width="68px"
          padding="100px 0"
          {...theme.loadingSpinner}
        />
      </>
    </ContentContainer>
  )
})
