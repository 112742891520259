import {
  Button,
  ContentContainer,
  CountrySelect,
  Header,
  LoadingSpinner,
  Symbol,
} from '../../components/Generic'
import { observer } from 'mobx-react'
import React, { useContext, useEffect, useState } from 'react'
import { StoreContext } from '../../components/App'
import { actionReport } from '../../methods/actionReport'
import { axiosInstance } from '../../methods/axiosConfig'
import {
  ButtonText,
  CountryWrap,
  DocumentPicker,
  DocumentTypeWrap,
  Label,
} from '../../styles/generic.styles'
import {
  ContentContainerBottomFixed,
  ContentContainerBottomLeft,
  MobileSpacerForContentContainerBottomFixed,
} from '../../styles/layout.styles'

export const SymbolTypes = [
  {
    value: 'ResidentPermit',
    label: 'Resident Permit',
  },
  {
    value: 'EUResidentPermit',
    label: 'EU Resident Permit',
  },
  {
    value: 'TravelTitle',
    label: 'Travel Title',
  },
  {
    value: 'DriverLicense',
    label: 'Driving license',
  },
  {
    value: 'Passport',
    label: 'Passport',
  },
  {
    value: 'IdentityCard',
    label: 'Identity card',
  },
]

export const IconTypes = [
  {
    value: 'residence-permit',
    label: 'ResidentPermit',
  },
  {
    value: 'residence-permit',
    label: 'EUResidentPermit',
  },
  {
    value: 'letter',
    label: 'TravelTitle',
  },
  {
    value: 'driving-license',
    label: 'DriverLicense',
  },
  {
    value: 'passport',
    label: 'Passport',
  },
  {
    value: 'identity-card',
    label: 'IdentityCard',
  },
]

export const DocumentTypeIkanoPage = observer(() => {
  const store = useContext(StoreContext)
  const { pageWidth } = store.AppState

  const { theme } = store.InterfaceState
  const { currentPageIndex, currentScenarioId, context } = store.ScenarioState
  const { documentTypePage: trans } = store.TranslationsState.translations

  const [docType, setDocType] = useState('')
  const [country, setCountry] = useState(null)
  const [apiCountries, setApiCountries] = useState([])
  const [countryDocTypes, setCountryDocTypes] = useState([])
  const [btnLoading, setBtnLoading] = useState(false)
  const [mobileVersion, setMobileVersion] = useState(false)

  const getDocTypes = async () => {
    try {
      const apiUrl = process.env.WEB_API_URL
        ? process.env.WEB_API_URL
        : 'https://dev-02-web-api.attempt.signicat.io'

      const res = await axiosInstance.get(
        `${apiUrl}/electronic-id/document-types`,
        {
          withCredentials: true,
        }
      )

      res.data.documentTypes &&
        setApiCountries(
          res.data.documentTypes.map((country) => {
            return {
              value: country.countryCode,
              label: `${country.countryName} | ${country.countryNameEN}`,
              icon: country.countryCode,
              ...country,
            }
          })
        )
    } catch (e) {
      throw e
    }
  }

  const handleProceed = async () => {
    if (currentScenarioId !== 'localTests') {
      setBtnLoading(true)
      store.AppState.setDocType(docType)

      const apiUrl = process.env.WEB_API_URL
        ? process.env.WEB_API_URL
        : 'https://dev-02-web-api.attempt.signicat.io'

      await axiosInstance.post(
        `${apiUrl}/electronic-id/select-document-type`,
        {
          id: Number(docType),
        },
        {
          withCredentials: true,
        }
      )
      setBtnLoading(false)
    }

    store.ScenarioState.setCurrentPage(currentPageIndex + 1)
  }

  const handleDocumentType = (type: string, report?) => {
    setDocType('' + type)

    if (!report || (report && report !== false)) {
      actionReport({
        type: 'event.onboarding-web.document-selection.DOCUMENT_SELECTED',
        payload: {
          selectedDocument: '' + type,
        },
      })
    }
  }

  const handleCountry = (country, report?) => {
    if (country) {
      const countryApiObj = apiCountries.find(
        (apiObj) => apiObj.countryCode === country.value
      )

      setCountry(countryApiObj)
      setCountryDocTypes(countryApiObj.types)
      setDocType('')

      if (!report || (report && report !== false)) {
        actionReport({
          type: 'event.onboarding-web.document-selection.COUNTRY_SELECTED',
          payload: {
            country: country.value,
          },
        })
      }
    } else {
      setCountry(null)
      setDocType('')
    }
  }

  useEffect(() => {
    if (apiCountries.length === 1) {
      handleCountry(apiCountries[0], false)
    }

    if (currentScenarioId === 'localTests') {
      const countryApiObj = apiCountries.find(
        (apiObj) => apiObj.countryCode === 'PL'
      )

      setCountry(countryApiObj)
      setDocType('IdentityCard')
    }
  }, [apiCountries])

  useEffect(() => {
    if (countryDocTypes.length === 1) {
      handleDocumentType(countryDocTypes[0].id)
    }
  }, [countryDocTypes])

  const getDocLabel = (value) => {
    return trans[value]
  }

  const getDocIcon = (value) => {
    const typeIcon = IconTypes.find((docType) => docType.label === value)

    return typeIcon ? typeIcon.value : value
  }

  useEffect(() => {
    if (pageWidth && pageWidth <= 980) {
      setMobileVersion(true)
    }
  }, [pageWidth])

  useEffect(() => {
    localStorage.setItem('permRetries', '0')
    if (!context?.shouldSelectDocument) {
      store.ScenarioState.setCurrentPage(currentPageIndex + 1)
    }
    getDocTypes()
  }, [])

  return (
    <ContentContainer
      {...theme.container}
      width={theme.contentWrap.width || '560px'}
      paddingMobile="20px"
      marginMobile="0"
    >
      <Header {...theme.header} fontFamily={theme.globals.fontFamilyHeadline}>
        {trans.header}
      </Header>
      <CountryWrap>
        {mobileVersion ? (
          <>
            {' '}
            <CountrySelect
              externalList={apiCountries}
              value={country}
              onChange={(value) => handleCountry(value)}
              autoFocus={true}
              defaultMenuIsOpen={false}
              placeholder={trans.inputPlaceholder}
              {...theme.selectInput}
            />
          </>
        ) : (
          <>
            <CountrySelect
              externalList={apiCountries}
              value={country}
              onChange={(value) => handleCountry(value)}
              autoFocus={false}
              defaultMenuIsOpen={false}
              placeholder={trans.inputPlaceholder}
              {...theme.selectInput}
            />
          </>
        )}
      </CountryWrap>

      {country && countryDocTypes ? (
        <DocumentTypeWrap border={theme.container.border}>
          <Label data-testid="chooseDocument">{trans.chooseDocument}</Label>
          {countryDocTypes.map((type) => {
            return mobileVersion ? (
              <DocumentPicker
                onClick={() => handleDocumentType(type.id)}
                borderRadius={theme.container.borderRadius}
                borderWidth={theme.selectInput.borderWidth}
                currentBorder={theme.selectInput.borderColorFocus}
                currentBg={theme.selectInput.currentBg}
                className={'' + type.id === docType ? 'current' : ''}
                padding="8px"
                lineHeight="46px"
                marginBottom="10px"
                id={type.name}
              >
                <Symbol
                  key={type.id}
                  {...theme.symbol}
                  type={getDocIcon(type.name)}
                  size="48px"
                />
                <span>{getDocLabel(type.name)}</span>
              </DocumentPicker>
            ) : (
              <DocumentPicker
                onClick={() => handleDocumentType(type.id)}
                borderRadius={theme.container.borderRadius}
                currentBorder={theme.selectInput.borderColorFocus}
                currentBg={theme.selectInput.currentBg}
                className={'' + type.id === docType ? 'current' : ''}
                id={type.name}
              >
                <Symbol
                  key={type.id}
                  {...theme.symbol}
                  type={getDocIcon(type.name)}
                  size="64px"
                />
                <span>{getDocLabel(type.name)}</span>
              </DocumentPicker>
            )
          })}
        </DocumentTypeWrap>
      ) : (
        <></>
      )}
      <ContentContainerBottomFixed>
        {!mobileVersion ? <ContentContainerBottomLeft /> : <div />}
        <Button
          onClick={() => handleProceed()}
          disabled={!(docType && docType.length)}
          {...theme.button}
          width="164px"
          paddingMobile="14px 40px"
          id="page-submit"
        >
          {btnLoading ? (
            <LoadingSpinner
              width="16px"
              {...theme.loadingSpinner}
              padding="0 0"
              thickness={2}
            />
          ) : (
            <>
              <ButtonText>{trans.proceed}</ButtonText>
            </>
          )}
        </Button>
      </ContentContainerBottomFixed>
      <MobileSpacerForContentContainerBottomFixed />
    </ContentContainer>
  )
})
