import 'mobx-react-lite'
import React, { FC, createContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useElementSize } from 'usehooks-ts'

import { useScreenSize } from '../hooks/useScreenSize'
import { devLog } from '../methods/devLog'
import { getQueryVariable } from '../methods/getQueryVariable'

import getUserAgent from '../methods/getUserAgent'
import { ThemeNames } from '../store/Interface.store'
import { Language } from '../types/translations'
import { rootStore } from '../store/Root.store'
import { ScenarioNames } from '../store/Scenario.store'
import { DocumentStyle } from '../styles/document.styles'
import { EIDStyle } from '../styles/eid.styles'
import FontStyles from '../styles/fonts.styles'
import { InputStyle } from '../styles/input.styles'
import { ContentRouter } from './ContentRouter/ContentRouter'
import { axiosInstance } from '../methods/axiosConfig'
import AxiosErrorHandler from '../contexts/AxiosErrorHandler'
import { usePageVisibility } from '../hooks/usePageVisibility'

export const StoreContext = createContext(rootStore)

export const App: FC = () => {
  const [paramEnv, setParamEnv] = useState('')
  const [appRef, { width, height }] = useElementSize()
  const screenSize = useScreenSize()
  const isVisible = usePageVisibility()

  // theme
  if (!rootStore.InterfaceState.themeId) {
    const themeId = getQueryVariable('themeId')
    devLog('themeId: ', themeId)

    rootStore.InterfaceState.setTheme(
      themeId ? (themeId as ThemeNames) : ('signicatTheme' as ThemeNames)
    )
  }

  useEffect(() => {
    handleInit()
    const UserAgent = getUserAgent()
    rootStore.AppState.setUserAgent(UserAgent)

    return () => {
      rootStore.AppState.setShouldRunHandleVisibilityChange(false)
    }
  }, [])

  useEffect(() => {
    if (
      !isVisible &&
      rootStore.InterfaceState.sendBeforeunloadEvent &&
      rootStore.AppState.shouldRunHandleVisibilityChange
    ) {
      navigator.sendBeacon(
        `${process.env.WEB_API_URL}/${process.env.ONBOARDING_WEB_API_PATH}/audit-log-gateway`,
        JSON.stringify({
          type: 'event.onboarding-web.process.TAB_CLOSE_REQUESTED',
          payload: {},
        })
      )
    }
  }, [isVisible])

  const getVideoIdContext = async (): Promise<void> => {
    try {
      const apiUrl = process.env.WEB_API_URL

      const res = await axiosInstance.get(
        `${apiUrl}/electronic-id/get-context`,
        {
          withCredentials: true,
        }
      )

      const {
        shouldAskForEmail,
        shouldAskForPhoneNumber,
        shouldSelectDocument,
        showVideoIdHintsScreen,
        env,
        language,
      } = res.data

      if (shouldAskForEmail) {
        rootStore.AppState.setShouldAskForEmail(shouldAskForEmail)
      }

      if (shouldAskForPhoneNumber) {
        rootStore.AppState.setShouldAskForPhoneNumber(shouldAskForPhoneNumber)
      }

      if (shouldSelectDocument) {
        rootStore.AppState.setShouldSelectDocument(shouldSelectDocument)
      }

      if (showVideoIdHintsScreen) {
        rootStore.AppState.setShowVideoIdHintsScreen(showVideoIdHintsScreen)
      }

      if (env) {
        setParamEnv(env)
      }

      if (language) {
        rootStore.TranslationsState.setTranslations(language)
      }
    } catch (e) {
      devLog(e)
    }
  }

  const handleVideoId = () => {
    const path = window.location.pathname.split('/')[1]
    if (
      [
        'video-id-retry',
        'video-id-retry-ikano',
        'video-id-retry-jitpay',
        'videoId',
      ].includes(path)
    ) {
      getVideoIdContext()
    }

    return <></>
  }

  useEffect(() => {
    handleVideoId()
  }, [])

  const handleInit = async () => {
    try {
      // language
      const language = getQueryVariable('language')
      devLog('language: ', language)
      if (language) {
        rootStore.TranslationsState.setTranslations(language as Language)
      }

      // scenario
      const path = window.location.pathname.split('/')[1]
      devLog('path: ', path)
      if (path) rootStore.ScenarioState.init(path as ScenarioNames)
    } catch (e) {
      devLog(e)
    }
  }

  useEffect(() => {
    rootStore.AppState.setPageWidth(width)
  }, [width])

  useEffect(() => {
    rootStore.AppState.setPageHeight(height)
  }, [height])

  useEffect(() => {
    rootStore.AppState.setScreenSize(screenSize)
  }, [screenSize])

  return (
    <StoreContext.Provider value={rootStore}>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/assets/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/assets/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/assets/favicon-16x16.png"
        />
        <meta name="description" content="Onboarding Web" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;1,200;1,300;1,400;1,600;1,700;1,800&display=swap"
          rel="stylesheet"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap"
          rel="stylesheet"
        ></link>
        {window.location.pathname.split('/')[1] === 'kontomatik' && (
          <script src="https://signin.kontomatik.com/assets/signin-widget.js"></script>
        )}
        {/* {isDev() === true && (
          <script src="https://etrust-sandbox.electronicid.eu/js/videoid-3.x/videoid.js"></script>
        )}
        {isDev() === false && (
          <script src="https://etrust-live.electronicid.eu/js/videoid-3.x/videoid.js"></script>
        )} */}

        {paramEnv === 'sandbox' && (
          <script src="https://etrust-sandbox.electronicid.eu/js/videoid-3.x/videoid.js"></script>
        )}
        {paramEnv === 'live' && (
          <script src="https://etrust-live.electronicid.eu/js/videoid-3.x/videoid.js"></script>
        )}

        <title>Signicat Onboarding Process</title>
      </Helmet>
      <FontStyles />
      <DocumentStyle
        bgColor={
          rootStore.AppState.iframeContent
            ? 'white'
            : rootStore.InterfaceState.theme.globals.bgColor
        }
        fontFamily={rootStore.InterfaceState.theme.globals.fontFamily}
      />
      <InputStyle
        container={rootStore.InterfaceState.theme.container}
        checkboxColor={rootStore.InterfaceState.theme.globals.checkboxColor}
        checkboxBorderWidth={
          rootStore.InterfaceState.theme.globals.checkboxBorderWidth
        }
        checkboxColorActive={
          rootStore.InterfaceState.theme.globals.checkboxColorActive
        }
        checkboxBorderRadius={
          rootStore.InterfaceState.theme.globals.checkboxBorderRadius
        }
        checkboxSize={rootStore.InterfaceState.theme.globals.checkboxSize}
        checkboxLabelFontSize={
          rootStore.InterfaceState.theme.globals.checkboxLabelFontSize
        }
        checkboxLabelLineHeight={
          rootStore.InterfaceState.theme.globals.checkboxLabelLineHeight
        }
        checkboxMarkLeft={
          rootStore.InterfaceState.theme.globals.checkboxMarkLeft
        }
      />
      <EIDStyle
        fontFamily={rootStore.InterfaceState.theme.globals.fontFamily}
        fontFamilyHeadline={
          rootStore.InterfaceState.theme.globals.fontFamilyHeadline
        }
        button={rootStore.InterfaceState.theme.button}
        videoIdPhoneChangeButton={
          rootStore.InterfaceState.theme.videoIdPhoneChangeButton
        }
        otpInput={rootStore.InterfaceState.theme.otpInput}
      />
      <AxiosErrorHandler>
        <div className="App" ref={appRef}>
          <ContentRouter />
        </div>
      </AxiosErrorHandler>
    </StoreContext.Provider>
  )
}
