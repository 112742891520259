import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  ContentContainer,
  Header,
  LoadingSpinner,
  SelectInput,
} from '../components/Generic'
import { observer } from 'mobx-react'
import { axiosInstance } from '../methods/axiosConfig'
import { forceRedirect } from '../methods/forceRedirect'
import { devLog } from '../methods/devLog'

import { StoreContext } from '../components/App'
import { ButtonText, SelectHolder, Label } from '../styles/generic.styles'
import { ContentContainerBottomFixedHorizontal } from '../styles/layout.styles'

interface Institution {
  bic?: string
  countries?: string[]
  id?: string
  logo?: string
  name?: string
  transactionTotalDays?: number
}

interface BankData {
  country: string
  institutions: Institution[]
}

export const GocardlessBankSelectionPage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.InterfaceState
  const { currentPageIndex } = store.ScenarioState
  const { gocardlessBankSelectionPage: trans } =
    store.TranslationsState.translations
  const { selectedCountry } = store.AppState

  const [loading, setLoading] = useState(false)

  const [bankList, setBankList] = useState([])
  const [bank, setBank] = useState<{ value: string; label: string }>()

  const handleProceed = async () => {
    setLoading(true)

    try {
      const { data } = await axiosInstance.post<{
        redirectUrl: string
      }>(
        '/gocardless/start-session',
        {
          institutionId: bank.value,
        },
        {
          withCredentials: true,
        }
      )

      if (data.redirectUrl) {
        forceRedirect(data.redirectUrl)
      }
    } catch (error) {
      devLog(error)
      setLoading(false)
    }
  }

  const fetchBankList = async () => {
    setLoading(true)
    try {
      const { data } = await axiosInstance.get<BankData>(
        '/gocardless/list-institutions',
        {
          withCredentials: true,
          params: {
            country: selectedCountry,
          },
        }
      )
      if (data.institutions.length) {
        setBankList(data.institutions)
      }
      setLoading(false)
    } catch (error) {
      devLog(error)
    }
  }

  useEffect(() => {
    void fetchBankList()
  }, [])

  return (
    <ContentContainer
      {...theme.container}
      width={theme.contentWrap.width || '560px'}
      paddingMobile="20px"
      marginMobile="0"
    >
      {loading ? (
        <LoadingSpinner
          width="68px"
          padding="100px 0"
          height="68px"
          {...theme.loadingSpinner}
        />
      ) : (
        <>
          <Header
            {...theme.header}
            fontFamily={theme.globals.fontFamilyHeadline}
          >
            {trans.header}
          </Header>

          <SelectHolder>
            <Label>{trans.label}</Label>
            <SelectInput
              options={bankList.map((item) => ({
                value: item.id,
                label: item.name,
              }))}
              value={bank}
              onChange={(val) => setBank(val)}
              {...theme.selectInput}
            />
          </SelectHolder>

          <ContentContainerBottomFixedHorizontal mobileGap="16px">
            {!!currentPageIndex && (
              <Button
                onClick={() =>
                  store.ScenarioState.setCurrentPage(currentPageIndex - 1)
                }
                {...theme.button}
                width="164px"
                paddingMobile="14px 40px"
              >
                <ButtonText>{trans.back}</ButtonText>
              </Button>
            )}

            <Button
              disabled={!bank?.value}
              onClick={() => handleProceed()}
              {...theme[!bank ? 'buttonDisabled' : 'button']}
              width="164px"
              paddingMobile="14px 40px"
            >
              <ButtonText>{trans.proceed}</ButtonText>
            </Button>
          </ContentContainerBottomFixedHorizontal>
        </>
      )}
    </ContentContainer>
  )
})
