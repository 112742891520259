import styled, { createGlobalStyle } from 'styled-components'

export const InputStyle = createGlobalStyle`
  body{
    input[type="checkbox"] {
      padding: 0;
      height: initial;
      width: initial;
      margin-bottom: 0;
      display: none;
      cursor: pointer;
    }

    input:checked + .label-text:before {
      background-color: ${(props) =>
        props.checkboxColorActive ? props.checkboxColorActive : 'black'};
      border-color: ${(props) =>
        props.checkboxColor ? props.checkboxColorActive : 'gray'} !important;
      border-width: ${(props) =>
        props.checkboxBorderWidth
          ? props.checkboxBorderWidth
          : '2px'} !important;
    }

    input:checked + .label-text:after {
      content: '';
      display: block;
      position: absolute;

      //TODO
      top: 2px !important;
      left: ${(props) =>
        props.checkboxMarkLeft ? props.checkboxMarkLeft : '6px'};
      width: 6.3px;
      height: 9.3px;
      border: solid
        ${(props) =>
          props.container.bgColor ? `${props.container.bgColor}` : 'white'};
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }

    .label-text {
      position: relative;
      display: flex;
      font-size: ${(props) =>
        props.checkboxLabelFontSize ? props.checkboxLabelFontSize : '14px'};
      line-height: ${(props) =>
        props.checkboxLabelLineHeight ? props.checkboxLabelLineHeight : '22px'};
    }

    .label-text:before {
      width: ${(props) => (props.checkboxSize ? props.checkboxSize : '20px')};
      height: ${(props) => (props.checkboxSize ? props.checkboxSize : '20px')};
      content:'';
      -webkit-appearance: none;
      appearance: none;
      background-color: transparent;
      border-style: solid;
      border-color: ${(props) =>
        props.checkboxColor ? props.checkboxColor : 'gray'} !important;
      border-width: ${(props) =>
        props.checkboxBorderWidth
          ? props.checkboxBorderWidth
          : '2px'} !important;
      border-radius: ${(props) =>
        props.checkboxBorderRadius ? `${props.checkboxBorderRadius}` : '0'};
      padding: 8px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 10px;
      top: 0px;
      box-sizing: border-box;
    }
  }
`

export const TickAllStyle = styled.div<{
  padding: string
  margin: string
  minWidth: string
  width: string
  widthMobile: string
  height: string
  heightMobile: string
  fontSize: string
  fontSizeMobile: string
  fontWeight: string
  textDecoration: string
}>`
  box-sizing: border-box;
  padding: ${(props) => (props.padding ? props.padding : '20px 10px')};
  margin: ${(props) => (props.margin ? props.margin : '0')};
  min-width: ${(props) => (props.minWidth ? props.minWidth : 'auto')};
  width: ${(props) => (props.width ? props.width : 'auto')};
  height: ${(props) => (props.height ? props.height : 'auto')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : '14px')};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : '500')};
  text-decoration: ${(props) =>
    props.textDecoration ? props.textDecoration : 'none'};

  span {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  @media (max-width: 980px) {
    font-size: ${(props) =>
      props.fontSizeMobile ? props.fontSizeMobile : props.fontSize};
    width: ${(props) => (props.widthMobile ? props.widthMobile : '100%')};
    height: ${(props) => (props.heightMobile ? props.heightMobile : 'auto')};
    padding: ${(props) =>
      props.paddingMobile ? props.paddingMobile : '10px 15px'};
    margin: ${(props) => (props.marginMobile ? props.marginMobile : '0')};
  }
`
